.footer {
  transition: margin-left 0.2s;
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  margin-top: 2rem;
  border-top: 1px solid #dfe7ef;
}
