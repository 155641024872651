.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 7rem 2rem 2rem 4rem;
  transition: margin-left 0.2s;

  margin-left: 250px;
}

.main-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
