.sidebar {
  @apply shadow-md;

  position: fixed;
  width: 250px;
  height: calc(100vh - 9rem);
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  top: 7rem;
  left: 2rem;
  transition: transform 0.2s, left 0.2s;
  background-color: white;
}

.sidebar-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 3rem 0 1rem;
  list-style-type: none;
  user-select: none;

  ul {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: #2c7be5;
    list-style-type: none;

    .route-select {
      background-color: #e7f1ff;
      border-right: 3px solid #2c7be5;
    }

    .menu-item {
      display: flex;
      gap: 0.75rem;
      align-items: center;
      outline: none;
      cursor: pointer;
      text-decoration: none;
      padding: 1rem 1.75rem;
      transition: background-color 0.2s, box-shadow 0.2s;

      &:hover {
        background-color: #f6f9fc;
      }
    }
  }
}
